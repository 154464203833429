// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
  color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }

  .ps__thumb-y {
    // background-color: $grey500;
    // border-radius: 6px;
    // width: 5px;
    // right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    // width: 5px;
    // background-color: transparent !important;
    // z-index: 999;

    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }

  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }

  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }

  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(-10px);
  }

  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }

  25% {
    transform: translateX(-10px);
  }

  75% {
    transform: translateX(10px);
  }
}
.css-1sfvfr9-MuiTablePagination-root {
  // Styles for the class you want to remove or hide
  // For example, you can set display to none to hide it
  display: none !important;
}
.MuiPagination-ul{
  .Mui-selected{
    color:white !important;
    font-weight: bold;
  }
}
.MuiPaginationItem-text{
  color: #FF9A62 !important;
}
.css-1volmkc-MuiButtonBase-root-MuiMenuItem-root{
  position: relative;
  color:#FF9A62;
  padding: 12px 30px; /* Adjust padding as desired for the big size */
  font-size: 16px; /* Adjust font size as desired for the big size */
  &:hover {
    background: rgb(101, 181, 127);
    background: linear-gradient(120deg, rgba(101, 181, 127, 1) 0%, rgba(28, 103, 72, 1) 50%, rgba(101, 181, 127, 1) 100%) !important;
    border-radius: 10px; 
    color:white; 
  }
}
.border {
  border: 1px solid #499662;
  border-radius: 10px;
  padding: 16px;

  &:hover {
    border: 1px solid #218838;
  }
}

.boxSelected {
  border-color: #218838;
  background: #e8f4fd;
}

.responsiveDialog {
  z-index: 2000;

  .MuiDialog-paper {
    width: 100%;
    max-height: 435px;
  }  
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .MuiDialog-paper {
      width: 100%;
      max-height: 435px;
    }
  }
}

#menu- {
  z-index: 3000;
  ul {
    height: 200px;
    overflow-y: scroll;
  }
}

.brandDialog {
  .MuiDialog-paper {
    width: 100%;
    max-height: 570px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .MuiDialog-paper {
      width: 100%;
      max-height: 530px;
    }
  }
}

.responsiveSelectfield {
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .css-1ku04m1-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
      width: '50%';
    }
  }
}
.marginOverrite {
  margin-top: 0px;
}
.customLetterChip {
  margin: 3px 10px;
  cursor: pointer;
  font-size: 89px;
}
.skillLetterChip {
  margin: 3px 0px;
  font-size: 17px;
}
.chooseFileInput {
  border: 1px solid #499662;
  padding: 10px;
  background-color: #fafafa;
  border-radius: 10px;
  position: relative;

  &::before {
    border-bottom: none;
  }

  &::after {
    border-bottom: none;
  }

  &:focused {
    border-bottom: none;
  }

  &:hover {
    &:not(.Mui-disabled) {
      &:before {
        border-bottom: none;
      }
    }
  }
}
.uploadIcon {
  position: absolute;
  top: 109px;
  left: 64px;
}
.fileError {
  color: #f44336;
  margin-left: 70px;
  margin-top: -12px;
}
.chooseFileError {
  color: #f44336;
  margin-left: 120px;
  margin-top: -12px;
}
.weight {
  margin: -20px;
}
.custom-density-selector {
  color: red !important;
}

.questionForm {
  width: 100%;
  padding: 10px 15px 10px;
}

.newClass {
  margin-top: 0.9%;
}
.error-message {
  color:rgba(255, 154, 98, 1);
  font-size: 12px;
  font-weight: normal;
}
.customOutlinedInput {

  margin-top: 8%;
}
.customOutlinedInput {
  .MuiOutlinedInput-root {
    height: 40px;
  }
}
.image-with-margin {
  width: 100%;
  height: 100%;
  margin-left: -20px;
}
.displayFlex {
  display: flex;
  overflow: hidden;
}
.displayText {
  white-space: nowrap;
  /* width: 100px; */
  overflow: hidden;
}
.audioPlayer {
  width: 250px;
  height: 44px;
  margin: 2px 2px 2px 0px;
}
.questionCardContent {
  padding-bottom: 0px !important;
}
.viewAudioPlayer {
  width: 100%;
  height: 44px;
  margin: 2px 2px 2px 0px;
}
.optionImageContainer {
  border: 1px solid;
  border-color: #499662;
 /*  border-color: #5f5f5f65; */
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
  height: 325px;
}
.login-btn,
.custom-button,
.cardBackgroundColor{
  background: rgb(101, 181, 127);
  background: linear-gradient(120deg, rgba(101, 181, 127, 1) 0%, rgba(28, 103, 72, 1) 50%, rgba(101, 181, 127, 1) 100%) !important;
}
.custom-button {
  position: relative;
  border: 1px solid #499662;
  color: white;
  padding: 12px 50px; /* Adjust padding as desired for the big size */
  font-size: 20px; /* Adjust font size as desired for the big size */
  text-align: right;
  border-radius: 10px;
}

.date {
  // fullwidth: 10%;
  padding: 12px 50px;
  margin: 2px 0;
  border: 2px solid #499662;
  justify-content: flex-start;
  border-radius: '10px';
  color:rgba(255, 154, 98, 1);
  font-size: large;
}

.promoFields {
  // fullwidth: 10%;
  padding: 12px;
  width: 100%;
  margin: 2px 0;
  border: 2px solid #499662;
  justify-content: flex-start;
  border-radius: '10px';
  color:rgba(255, 154, 98, 1);
  font-size: large;
}
.size {
  font-size: large;
  font-weight: 500;
}
.selectedOptionImageContainer {
  position: relative;
  border: 1px solid #499662;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
}
.radioGroupDesign {
  position: absolute;
  top: -1px;
}
.imageSize {
  width: 100%;
  height: 300px;
}
.letterStyle {
  margin-left: 3px;
  font-size: 17px;
  font-weight: 500;
  color: white;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.75;
}
.height {
  height: 130px;
  background-color: #499662;
  color: white;
  text-align: center;
  font-size: large;
  
}

.heading {
  color:#FF9A62;
}
.searchbydate {
  color: #499662;
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  // padding-top: 16px;
}
.calender {
  padding: 12px 50px;
  margin: 2px 0;
  border: 2px solid #499662;
  justify-content: flex-start;
  border-radius: '10px';
  color:rgba(255, 154, 98, 1);
  font-size: large;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.fill {
  fill: #499662;
}

.headingshade {
  color: #499662;
  font-size: 2rem;
  font-weight: 500;
}
.letterSize {
  font-size: 30px;
}
.exportExcel {
  background:  #507d2a;
  font-size: 20px !important;
  border-radius: 10px !important;
  padding: 14px !important;
  cursor: pointer;
}

.selectField {
  .css-1fmjr7l-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 11px 14px;
  }
  .MuiOutlinedInput-root {
    height: 40px;
  }
}

.dropZone {
  outline: none;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  border-radius: 1px;
  background-color: #f3fafc;
  border: 1px dashed #499662;
  &:hover {
    opacity: 0.72;
    cursor: 'pointer';
  }
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

section {
  width: 100%;
  padding: 1px;
  min-height: calc(100vh - 80px);
}

section .form_data {
  max-width: 550px;
  margin: 25px auto;
  padding: 35px 10px;
  display: flex;
  background-color: white;
  padding: 20px;
  border:1px solid #499662;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7%;
}

.text-align {
  //    background-color: white;
}
.form_heading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form_data h1 {
  font-size: 34px;
  color: #499662;
}

.form_data p {
  color: #499662;
  font-size: 15px;
  margin-top: 9px;
}

.form_data form {
  margin-top: 45px;
  width: 100%;
  padding: 10px 50px;
}
.color {
  text-decoration: #499662;
}
.top {
  margin-top: -19ch;
}

.resettop {
  margin-top: 9ch;
}
.giver-amount-header {
  font-size: 15px;
  // margin: 50px;
  margin-left: 0.5%;
}
.custom-amount-field {
  margin-left: 0.9%;
}
.amount-field {
  margin-left: -0.5%;
}
.custom-amount-field .form_data form .form_input input {
  width: 100%;
  padding: 11px;
  border: 1px solid #499662;
  border-radius: 10px;
  outline: none;
  margin-bottom: 14px;
  margin-top: 9px;
  font-size: 14px;
}
.form_data form .form_input label {
  font-weight: 600;
  font-size: 15px;
}

.form_input .two {
  display: flex;
  align-items: center;
  position: relative;
}

.form_data form .form_input input:focus {
  border: 2px solid #499662;
}

.form_data form .form_input input::placeholder {
  color: #499662;
  letter-spacing: 0.5px;
  font-size: 14px;
}

.form_input .showpass {
  position: absolute;
  right: 0;
  top: 1px;
  padding: 7px 10px;
  margin-right: 5px;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 600;
  color: #d6d6d6;
  background-color: #499662;
  border-radius: 10px;
  cursor: pointer;
}

form .btn {
  display: block;
  width: 100%;
  padding: 15px;
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: #499662;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

form .btn:hover {
  background-color: #499662;
}
form .btnload {
  background-color: #499662;
}

.form_data form p {
  text-align: center;
  margin-top: 20px;
}

.form_data form a {
  text-decoration: none;
  color: #499662;
  border-bottom: 1px solid #499662;
}
.dropZoneContainer {
  color: #2e2929;
  border: 1px solid #499662;
  padding: 24px 28px;
  background-color: #f3fafc;
  border-radius: 10px;

  & .css-8m9ipk-MuiTypography-root {
    white-space: 'nowrap';
    width: '360px';

    text-overflow: 'ellipsis';
  }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    padding: '10px';
    & .css-8m9ipk-MuiTypography-root {
      white-space: 'nowrap';
      width: '166px';

      text-overflow: 'ellipsis';
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    padding: '10px';
    & .css-8m9ipk-MuiTypography-root {
      white-space: 'nowrap';
      width: '360px';
      overflow: 'hidden';
      text-overflow: 'ellipsis';
    }
  }
}

.isDragActive {
  opacity: 0.72;
}
.isDragReject {
  color: #f3fafc;
  border-color:#499662;
  background-color: #f3fafc;
}
.text {
  color: #499662;
}
.list {
  margin: 12px;
}
.listItem {
  border: solid 1px #499662;
  margin: 8px 0px;
  padding: 4px 16px;
  border-radius: 10px;
  background-color: #fff;
}
.quantityField {
  width: 100px;
  & .css-4pzdr3-MuiInputBase-input-MuiOutlinedInput-input {
    height: 0.8375em;
  }
}
.css-169iwlq-MuiCalendarPicker-root {
  max-height: 270px !important;
}
.css-1984sxt-MuiCardMedia-root {
  background:  #507d2a;
}
.css-1arijsr-MuiSnackbar-root {
  z-index: 2400;
}
.customScroller {
  max-height: 150px;
  width: 270px;
  overflow-y: scroll;
  label {
    width: 250px;
  }
}
.color {
  color: #499662;
  font-size: medium;
}

.css-1nvdc5e-MuiCardHeader-root {
  padding: 14px 20px 14px 20px !important;
}
.customCheckBox {
  position: absolute;
  .css-i4bv87-MuiSvgIcon-root {
    fill: #f3fafc !important;
  }
}
.customLink {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nftTypeFilter {
  .MuiFormGroup-root {
    flex-direction: column;
  }
}
.nftCardMoreHorizIcon {
  background-color: white;
  border-radius: 50%;
}
.custom-checkbox {
  -webkit-appearance: none;
  background-color: white;
  padding: 8px;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  top: 6px;
  border: 1px solid #499662;
  position: absolute;
  margin-left: 10px;
  // cursor: pointer;
}

.custom-checkbox:checked {
  background-color: #507d2a;
  // background-color: black;
  border: 1px solid #499662;
}

.custom-checkbox:checked:after {
  content: '\2714';
  font-size: 10px;
  position: absolute;
  top: 1px;
  left: 4px;
  color: #fff;
}

.custom-checkbox:focus {
  outline: none;
}
.cardheight {
  height: 60px;
}

.color {
  text-decoration-color: #499662;
  font-size: 28px;
}
.actionItem {
  display: flex;
  // padding: 5px;
  // background-color: #f3f3f3;
  width: 100%;
  margin-bottom: 2px;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  img {
    cursor: pointer;
    margin-right: 5px;
  }
  svg {
    // margin-right: 3px;
  }
}
.customMenuClass {
  .MuiPaper-root {
    box-shadow: none !important;
    border: 1px solid#499662;
    ul {
      li {
        padding: 0 10px 0 10px;
      }
    }
  }
}
.customTableMenuClass {
  .MuiPaper-root {
    box-shadow: none !important;
  
    ul {
      li {
        padding: 0 10px 0 10px;
      }
    }
  }
}

.userActiveContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  .activeDot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background:  #507d2a;
  }
}
.image-container {
  display: flex;
  align-items: center;
}
// ====================Graph=======================
.graph{
  border:#499662 1px solid;
}
//=======================mui list==================
#menu- ul{
  height: auto !important;
  overflow-y: hidden;
}
